const en = {
  btn: {
    cabinet: "Personal account",
    register: "Register",
    trader: "Go to trade",
    more: "Learn More",
    terminal: "Trading terminal",
    openAccount: "Open an account",
    login: "Log in",
    openPlatform: "Open Platform",
    startTrading: "Start Trading",
    continue: "Continue",
    readMore: "Read more",
  },
  form: {
    phone: "Phone",
  },
  menu: {
    terminal: "Trade terminal",
    contacts: "Contacts",
    about: "About Us",
    terms: "Terms",
    trading: "Trading",
    markets: "Markets",
  },
  subMenu: {
    terminal: "Trading terminal",
    fundament: "Fundamentals of Trading",
    fAnalysis: "Fundamental Analysis",
    tAnalysis: "Technical Analysis",
    faq: "FAQ",
  },
  customer: "Client Agreement",
  privacy: "Privacy Policy",
  refund: "Return policy",
};

export default en;
