<template>
  <RouterView />
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "EmptyLayout",
  beforeMount() {
    AOS.init({
      disable: function () {
        var maxWidth = 1200;
        return window.innerWidth < maxWidth;
      },
      duration: 1000,
    });
  },
};
</script>

<style scoped></style>
