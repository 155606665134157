import requestService from "@/services/requestService";

const userService = {
  async login(loginData) {
    const response = await requestService.post("/auth/login", loginData);

    return response.data;
  },

  async register(registerData) {
    const response = await requestService.post("/auth/register", registerData);
    return response.data;
  },

  async forgot(email) {
    const response = await requestService.post("/auth/forgot", { email });

    return response.data;
  },

  async recovery(token, password, email, password_confirmation) {
    const response = await requestService.post(`/auth/reset`, {
      password,
      token,
      email,
      password_confirmation,
    });

    return response.data;
  },
};

export default userService;
