<template>
  <div>
    <AppHeader />
    <div class="section-auth">
      <section class="section-lg">
        <router-view />
      </section>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/app/AppHeader.vue";
export default {
  name: "AuthLayout",
  components: { AppHeader },
};
</script>

<style scoped></style>
