const fr = {
  btn: {
    cabinet: "Cabinet personnel",
    register: "Registre",
    trader: "Démarrage",
    more: "Pour apprendre plus",
    terminal: "Terminal de négociation",
    openAccount: "Ouvrir un compte",
    login: "Entrer",
    openPlatform: "Plateforme ouverte",
    startTrading: "Commencer à trader",
    continue: "Continuer",
    readMore: "En savoir plus",
  },
  form: {
    phone: "Téléphone",
  },
  menu: {
    terminal: "Terminal de négociation",
    contacts: "Contact",
    about: "À propos de nous",
    terms: "Conditions",
    trading: "Négociation",
    markets: "Marchés",
  },
  subMenu: {
    terminal: "Terminal de trading",
    fundament: "Principes de base de la négociation",
    fAnalysis: "Analyse fondamentale",
    tAnalysis: "Analyse technique",
    faq: "FAQ",
  },
  customer: "Accord client",
  privacy: "Politique de confidentialité",
  refund: "Politique de retour",
};

export default fr;
