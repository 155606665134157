import Vue from "vue";
import VueRouter from "vue-router";
import BaseLayout from "@/layouts/BaseLayout.vue";
import EmptyLayout from "@/layouts/EmptyLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import { i18n } from "@/main";

Vue.use(VueRouter);

const routes = [
  { path: "/en", redirect: { path: "/" } },
  {
    path: "/:locale(fr|de)?",
    component: EmptyLayout,
    beforeEnter: languageMiddleware,
    children: [
      {
        path: "",
        component: BaseLayout,
        children: [
          {
            path: "",
            name: "Home",
            component: () =>
              import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
          },
          {
            path: "about",
            name: "About",
            component: () => import("@/views/AboutView.vue"),
          },
          {
            path: "markets",
            name: "Markets",
            component: () => import("@/views/MarketsView.vue"),
          },
          {
            path: "terms-of-trade",
            name: "TradeTerms",
            component: () => import("@/views/TermsOfTradeView.vue"),
          },
          {
            path: "contacts",
            name: "Contacts",
            component: () => import("@/views/ContactsView.vue"),
          },
          {
            path: "privacy-policy",
            name: "Privacy",
            component: () => import("@/views/PrivacyView.vue"),
          },
          {
            path: "trade",
            component: EmptyLayout,
            children: [
              {
                path: "terminal",
                name: "Terminal",
                component: () => import("@/views/trade/TerminalView.vue"),
              },
              {
                path: "fundamentals-of-trading",
                name: "FundamentalsTrading",
                component: () => import("@/views/trade/FundamentTradeView.vue"),
              },
              {
                path: "fundamentals-analysis",
                name: "FundamentalsAnalysis",
                component: () =>
                  import("@/views/trade/FundamentAnalisView.vue"),
              },
              {
                path: "technical-analysis",
                name: "TechnicalAnalysis",
                component: () =>
                  import("@/views/trade/TechnicalAnalisView.vue"),
              },
              {
                path: "faq",
                name: "Faq",
                component: () => import("@/views/trade/FaqView.vue"),
              },
            ],
          },
          {
            path: "activation/success",
            name: "Activation",
            component: () =>
              import(
                /* webpackChunkName: "auth-password-reset" */ "../views/auth/ActivationView.vue"
              ),
          },
          {
            path: "activation/error",
            name: "ActivationError",
            component: () =>
              import(
                /* webpackChunkName: "auth-password-reset" */ "../views/auth/ActivationErrorView"
              ),
          },
          {
            path: "withdrawal/success",
            name: "Withdraw",
            component: () =>
              import(
                /* webpackChunkName: "auth-withdraw" */ "../views/auth/WithdrawView.vue"
              ),
          },
          {
            path: "withdrawal/error",
            name: "WithdrawError",
            component: () =>
              import(
                /* webpackChunkName: "auth-withdraw-error" */ "../views/auth/WithdrawErrorView.vue"
              ),
          },
          {
            path: "transaction/success",
            name: "Transaction",
            component: () =>
              import(
                /* webpackChunkName: "auth-transaction-success" */ "../views/auth/TransactionView.vue"
              ),
          },
          {
            path: "transaction/error",
            name: "TransactionError",
            component: () =>
              import(
                /* webpackChunkName: "auth-transaction-error" */ "../views/auth/TransactionErrorView.vue"
              ),
          },
        ],
      },
      {
        path: "auth",
        component: AuthLayout,
        children: [
          {
            path: "login",
            name: "Login",
            component: () =>
              import(
                /* webpackChunkName: "auth-login" */ "../views/auth/LoginView.vue"
              ),
          },

          {
            path: "register",
            name: "Registration",
            component: () =>
              import(
                /* webpackChunkName: "auth-registration" */ "../views/auth/RegistrationView.vue"
              ),
          },
          {
            path: "recovery",
            name: "PasswordReset",
            component: () =>
              import(
                /* webpackChunkName: "auth-password-reset" */ "../views/auth/PasswordResetView.vue"
              ),
          },

          {
            path: "forgot-password",
            name: "ForgotPassword",
            component: () =>
              import(
                /* webpackChunkName: "auth-forgot-password" */ "../views/auth/ForgotView.vue"
              ),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 1, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

function languageMiddleware(to, from, next) {
  const locale = to.params.locale || "en";

  i18n.locale = locale;

  document.querySelector("html").setAttribute("lang", locale);

  return next();
}

export const localePath = function (route, lang) {
  let locale = lang;

  if (locale === undefined) {
    locale = i18n.locale;
  }

  if (locale === "en") return route;
  return Object.assign(route, {
    params: {
      locale,
      ...route.params,
    },
  });
};

Vue.prototype.$localePath = localePath;

Vue.prototype.$switchLocalePath = function (lang) {
  const path = { ...router.currentRoute };

  let locale = lang;

  if (locale === undefined) {
    locale = i18n.locale;
  }

  if (locale === "en") locale = null;

  return Object.assign(path, {
    params: {
      locale,
    },
  });
};
