const ru = {
  btn: {
    cabinet: "Личный кабинет",
    register: "Зарегистрироваться",
    trader: "Перейти к торговле",
    more: "Узнать больше",
    terminal: "Торговый терминал",
    openAccount: "Открыть счет",
    login: "Войти",
    openPlatform: "Открыть платформу",
    startTrading: "Начать торговать",
    continue: "Продолжить",
    readMore: "Подробнее",
  },
  form: {
    phone: "Tелефон",
  },
  menu: {
    terminal: "Торговый терминал",
    contacts: "Контакты",
    about: "O нас",
    terms: "Условия",
    trading: "Торговля",
    markets: "Рынки",
  },
  subMenu: {
    terminal: "Торговый терминал",
    fundament: "Основы трейдинга",
    fAnalysis: "Фундаментальный анализ",
    tAnalysis: "Технический анализ",
    faq: "Частые вопросы",
  },
  customer: "Клиентское соглашение",
  privacy: "Политика конфиденциальности",
  refund: "Политика возврата платежей",
};

export default ru;
