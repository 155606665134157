const de = {
  btn: {
    cabinet: "Persönliches Kabinett",
    register: "Registrieren",
    trader: "Start",
    more: "Mehr erfahren",
    terminal: "Handelsterminal",
    openAccount: "Ein Konto eröffnen",
    login: "Betreten",
    openPlatform: "Offene Plattform",
    startTrading: "Handel beginnen",
    continue: "Weitermachen",
    readMore: "Mehr lesen",
  },
  form: {
    phone: "Telefon",
  },
  menu: {
    terminal: "Handels-Terminal",
    contacts: "Kontakt",
    about: "Über uns",
    terms: "Bedingungen",
    trading: "Handel",
    markets: "Märkte",
  },
  subMenu: {
    terminal: "Handels-Terminal",
    fundament: "Grundlagen des Handels",
    fAnalysis: "Fundamentale Analyse",
    tAnalysis: "Technische Analyse",
    faq: "FAQ",
  },
  customer: "Kundenvereinbarung",
  privacy: "Datenschutzrichtlinie",
  refund: "Rückgaberecht",
};

export default de;
